<template>
  <router-view></router-view>
</template>
<script>
import Vue from 'vue';
export default {
 
  created() {
    Vue.prototype.base64_encode = (i)  => btoa(i);
    Vue.prototype.base64_decode = (i)  => atob(i);
    let href = window.location.href;
    let host = window.location.hostname;
    if(host.includes("www.")) {
      host = host.replace('www.','')
    }
    if (host != '') {
      //localStorage.setItem('localelang', this.$store.state.lang.locale);
      //this.$auth.setVendorSettings({order_web: "deliciasbeef.app"});
      // this.$auth.setVendorSettings({order_web: host});
      this.$auth.setVendorSettings({order_web: host,is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});        
    }else{
      this.$auth.setVendorSettings({order_web: 'www.deliciasbeef.app',is_langauge: this.$store.state.lang.locale,latitude:localStorage.getItem("latitude"),longitude:localStorage.getItem("longitude")});        
    }
    this.$auth.setUser((type)=> {
      if (type) {
        let currentRoute = href.substring(href.lastIndexOf('/') + 1);
        if (currentRoute == 'sign-in'){
          this.$router.push({ name: type }).catch(()=>{});
        }
      }
    });
  },

  watch:{
    '$auth.setting': function(preval,newval) {
      if((preval == null || preval.length == 0) && this.$auth.init){
        this.$router.push({ name: "notfound" }).catch(()=>{});
      }
    }
  },
}
</script>