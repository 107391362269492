const baseUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;

let host = window.location.hostname;
let image_baseUrl = 'https://admin.deliciasbeef.app/public/';
let apiurl = 'https://admin.deliciasbeef.app/master/api/v1/';
let socketurl =  'https://admin.deliciasbeef.app:8000';
if(host == 'localhost'){
    apiurl = 'http://52.22.203.63/master/api/v1/';
    socketurl =  'http://52.22.203.63:8000';
    image_baseUrl = 'http://52.22.203.63/public/';
}

export default {
    appurl: baseUrl,
    base_folder: +window.location.pathname,
    coupon: image_baseUrl + 'media/Coupon/',
    applogo: image_baseUrl + 'media/foodapp/original/',
    api_url: apiurl,
    socket_url: socketurl,
    google_key: window.google_map_key
}
